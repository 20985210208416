import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { PartnerCompanyEntity } from 'src/entities/PartnerCompanyEntity';

export const Columns: (openEditDialog: (entity: PartnerCompanyEntity) => void) => GridColDef[] = (openEdit) => [
    {
      field: 'edit',
      headerName: '編集',
      disableExport: true,
      renderCell: (params: { row: PartnerCompanyEntity }) => (
        <IconButton onClick={() => openEdit(params.row)}>
          <EditOutlinedIcon />
        </IconButton>
      ),
      flex: 2,
      sortable: false,
      filterable: false,
    },
    {
      field: 'companyName',
      headerName: '事業所',
      flex: 10,
    },
    {
      field: 'name',
      headerName: '会社名',
      flex: 10,
    },
    {
      field: 'base',
      headerName: '拠点名',
      flex: 20,
    },
    {
      field: 'address',
      headerName: '拠点住所',
      flex: 30,
    },
    {
      field: 'contactPerson',
      headerName: '担当者',
      flex: 20,
    },
    {
      field: 'phoneNumber',
      headerName: '電話番号',
      flex: 20,
    },
    {
      field: 'category',
      headerName: 'カテゴリ',
      flex: 20,
    },
    {
      field: 'truckKlasses',
      headerName: '対応可能な車両タイプ',
      flex: 20,
    },
    {
      field: 'tempretureZones',
      headerName: '対応可能な温度帯',
      flex: 20,
    },
    {
      field: 'memo',
      headerName: 'メモ',
      flex: 20,
    },
  ];
