import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormGroup,
  FormLabel,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { FC, memo, useContext, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import MultiCheckbox from 'src/components/MultiCheckBox';
import LicenseContext from 'src/contexts/LicenseContext';
import { DesignatedTruckFeature } from 'src/entities/orderEntity';
import { PartnerCompanyEntity } from 'src/entities/PartnerCompanyEntity';
import { TruckEntity, TruckRequestEntity } from 'src/entities/truckEntity';
import { useMutationPartnerCompanies, usePartnerCompaniesRequest } from 'src/hooks/usePartnerCompanies.request';
import { useQueryGarages } from 'src/hooks/useQueryGarages';
import { useMutationTruck } from 'src/hooks/useQueryTrucks';
import { queryKey } from 'src/hooks/useTrucks.request';

import { schema } from './schema';

const options: (
  | 'パワーゲート'
  | '空調'
  | 'エアサス'
  | 'スタンション')[] = [
  'パワーゲート',
  '空調',
  'エアサス',
  'スタンション'
];

type Props = {
  isOpen: boolean;
  onClose: () => void;
  updateIsLoading: (isLoading: boolean) => void;
  editEntity?: TruckEntity;
}

const FormDialog: FC<Props> = memo((
  {
    isOpen,
    onClose,
    updateIsLoading,
    editEntity,
  }
) => {
  const licenseContext = useContext(LicenseContext);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [features, setFeatures] = useState<DesignatedTruckFeature[]>([]);
  const [companyId, setCompanyId] = useState<number>(0);

  const form = useForm<TruckEntity>({
    resolver: yupResolver(schema)
  });

  const {
    control,
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = form;

  const { data: partners } = usePartnerCompaniesRequest(0, '', '', Number.MAX_SAFE_INTEGER);
  const garages = useQueryGarages(0, { enabled: true });
  const { addTruck, updateTruck } = useMutationTruck();
  const { addPartnerCompany } = useMutationPartnerCompanies();

  const onChangeCompanyId = (value: number) => {
    setCompanyId(value);
  };

  const onSubmitForm: SubmitHandler<TruckEntity | TruckRequestEntity> = async (
    data
  ): Promise<void> => {
    try {
      if ('id' in data) {
        await updateTruck.mutateAsync(data);
      } else {
        await addTruck.mutateAsync(data);
      }
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, quotes,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-argument
      error.response.data.map((str) => enqueueSnackbar(str));
    } finally {
      queryClient.invalidateQueries([queryKey]).finally(() => {
        updateIsLoading(false);
        onClose();
      });
    }
  };

  const haveSelectableCompanies = licenseContext?.config?.selectable_companies?.length > 1;
  const isNew = !editEntity;

  useEffect(() => {
    if (!editEntity) {
      reset({});
      return;
    }

    reset(editEntity);
    setCompanyId(editEntity.company_id);
    setFeatures(editEntity.features);
  }, [editEntity, reset]);

  useEffect(() => {
    if (!licenseContext?.config?.selected_company_id) return;
    if (licenseContext?.config?.selected_company_id === 0) {
      setCompanyId(
        0
      );
      return;
    }

    setCompanyId(
      licenseContext?.config?.selected_company_id
    );
  }, [licenseContext?.config?.selected_company_id]);

  const [searchPartnerCompanyQuery, setSearchPartnerCompanyQuery] = useState('');
  const [selectedPartnerCompanyId, setSelectedPartnerCompanyId] = useState<number>(null);
  const [selectedPartnerCompanyName, setSelectedPartnerCompanyName] = useState<string>(editEntity?.partner_company_name);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handlePartnerCompanyOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePartnerCopmanyClose = () => {
    setAnchorEl(null);
  };

  const handlePartnerCompanySearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPartnerCompanyQuery(event.target.value);
  };

  const handlePartnerCompanyClick = (id: number | null) => {
    setSelectedPartnerCompanyId(id);
    setValue('partner_company_id', id);
    if (id !== null) {
      const selected = partners.data.find((it) => it.id === id);
      setSelectedPartnerCompanyName(selected.name);
    } else {
      setSelectedPartnerCompanyName(null);
    }
    setAnchorEl(null);
  };

  const handleCreatePartnerCompany = async () => {
    if (!searchPartnerCompanyQuery) return;
    await addPartnerCompany.mutateAsync({
      name: searchPartnerCompanyQuery,
    }, {
      onSuccess: (response: AxiosResponse<PartnerCompanyEntity>) => {
        setValue('partner_company_id', response.data.id);
        setSelectedPartnerCompanyId(response.data.id);
        setSelectedPartnerCompanyName(response.data.name);
        setAnchorEl(null);
      }
    });
  };

  const filteredPartners = partners?.data.filter((partner) => partner.name.toLowerCase().includes(searchPartnerCompanyQuery.toLowerCase()));

  if (!garages.data || !partners) return null;

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth>
      <DialogTitle>トラック追加</DialogTitle>
      <DialogContent>
        <Stack component="form" noValidate spacing={2}>
          {[haveSelectableCompanies, isNew].every((bool) => bool) && (
            <>
              <InputLabel>事業所</InputLabel>
              <Select
                id="company_id"
                margin="dense"
                label="事業所"
                type="number"
                fullWidth
                variant="standard"
                value={`${companyId}`}
                onChange={(e) => onChangeCompanyId(Number(e.target.value))}
                disabled={!haveSelectableCompanies}
              >
                {licenseContext.config.selectable_companies.map((company) => (
                  <MenuItem value={company.id} key={company.id}>{company.name}</MenuItem>
                ))}
              </Select>
            </>
          )}
          <Stack>
            <InputLabel id="garage_id">車庫</InputLabel>
            <Controller
              control={control}
              name="garage_id"
              render={({ field }) => (
                <Select
                  required
                  margin="dense"
                  id="garage_id"
                  label="車庫"
                  type="text"
                  fullWidth
                  variant="standard"
                  {...field}
                >
                  {garages.data.filter((it) => it.company_id === companyId).map((garage) => (
                    <MenuItem key={garage.id} value={garage.id}>
                      {garage.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Stack>
          <Stack direction="row" gap={1}>
            <Box gridColumn="span 1">
              <TextField
                id="maximum_loading_capacity_kg"
                label={`最大積載量(${licenseContext?.config?.unit})`}
                type="text"
                fullWidth
                variant="standard"
                required
                {...register('maximum_loading_capacity_kg')}
                error={'maximum_loading_capacity_kg' in errors}
                helperText={errors.maximum_loading_capacity_kg?.message}
              />
            </Box>
            <TextField
              required
              id="license_plate_value"
              label="トラック"
              type="text"
              fullWidth
              variant="standard"
              {...register('license_plate_value')}
              error={'license_plate_value' in errors}
              helperText={errors.license_plate_value?.message}
            />
            <TextField
              id="truck_number"
              label="車番"
              type="text"
              fullWidth
              variant="standard"
              {...register('truck_number')}
              error={'truck_number' in errors}
              helperText={errors.truck_number?.message}
            />
            <Box gridColumn="span 1">
              <TextField
                id="sort_order"
                label="並び順"
                type="number"
                variant="standard"
                {...register('sort_order')}
                error={'sort_order' in errors}
                helperText={errors.sort_order?.message}
              />
            </Box>
          </Stack>
          <Stack direction="row" gap={1}>
            <Box sx={{ borderRadius: '8px', backgroundColor: '#fff' }}>
              <InputLabel>運送会社</InputLabel>
              <Button
                variant="outlined"
                onClick={handlePartnerCompanyOpen}
                sx={{ textTransform: 'none', width: 200 }}
              >
                {selectedPartnerCompanyName ?? '運送会社を選択'}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handlePartnerCopmanyClose}
                PaperProps={{
                  style: { width: 250, padding: 0, margin: 0, },
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder="検索"
                  value={searchPartnerCompanyQuery}
                  onChange={handlePartnerCompanySearchChange}
                />
                <List sx={{ p: 0, m: 0, }}>
                  <ListItem
                    key={0}
                    button
                    onClick={() => handlePartnerCompanyClick(null)}
                  >
                    <ListItemText
                      primary="&nbsp;"
                    />
                  </ListItem>
                  {filteredPartners.map((partner) => (
                    <ListItem
                      key={partner.id}
                      button
                      onClick={() => handlePartnerCompanyClick(partner.id)}
                      selected={selectedPartnerCompanyId === partner.id}
                      sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    >
                      <ListItemText
                        primary={partner.name}
                        secondary={partner.memo}
                        primaryTypographyProps={{ fontSize: 14, color: 'black', }}
                        secondaryTypographyProps={{ fontSize: 12, }}
                      />
                    </ListItem>
                  ))}
                  {searchPartnerCompanyQuery && (
                    <>
                      <Divider sx={{ my: 1 }} />
                      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                      <MenuItem onClick={handleCreatePartnerCompany}>
                        <ListItemText primary={`"${searchPartnerCompanyQuery}" を新規作成`} />
                      </MenuItem>
                    </>
                  )}
                </List>
              </Menu>
            </Box>
          </Stack>
          <Stack direction="row" gap={1}>
            {
              licenseContext?.config?.unit_used_for_calculation === '重量と体積'
              && (
                <TextField
                  margin="dense"
                  id="loading_platform_volume_m3"
                  label="荷台の容量(立米)"
                  type="text"
                  fullWidth
                  variant="standard"
                  {...register('loading_platform_volume_m3')}
                  error={'loading_platform_volume_m3' in errors}
                  helperText={errors.loading_platform_volume_m3?.message}
                />
              )
            }
            <TextField
              margin="dense"
              id="max_num_pistons"
              label="上限ピストン回数"
              type="text"
              fullWidth
              variant="standard"
              {...register('max_num_pistons')}
              error={'max_num_pistons' in errors}
              helperText={errors.max_num_pistons?.message}
            />
            <TextField
              margin="dense"
              id="max_num_locations_per_pistons"
              label="１回転あたりの上限納品先件数"
              type="text"
              fullWidth
              variant="standard"
              {...register('max_num_locations_per_pistons')}
              error={'max_num_locations_per_pistons' in errors}
              helperText={errors.max_num_locations_per_pistons?.message}
            />
            <TextField
              margin="dense"
              id="maximum_loading_rate"
              label="上限積載率"
              type="text"
              fullWidth
              variant="standard"
              {...register('maximum_loading_rate')}
              error={'maximum_loading_rate' in errors}
              helperText={errors.maximum_loading_rate?.message}
            />
            <TextField
              margin="dense"
              id="minimum_loading_rate"
              label="下限積載率"
              type="text"
              fullWidth
              variant="standard"
              {...register('minimum_loading_rate')}
              error={'minimum_loading_rate' in errors}
              helperText={errors.minimum_loading_rate?.message}
            />
          </Stack>
          <Stack direction="row" gap={1}>
            <FormControl fullWidth>
              <InputLabel>車両タイプ</InputLabel>
              <Controller
                control={control}
                name="klass"
                render={({ field }) => (
                  <Select
                    id="klass"
                    label="車両タイプ"
                    type="text"
                    variant="standard"
                    {...field}
                    value={field.value || ''}
                  >
                    <MenuItem value={null}>&nbsp;</MenuItem>
                    <MenuItem value="軽貨物">軽貨物</MenuItem>
                    <MenuItem value="トラック">トラック</MenuItem>
                    <MenuItem value="セミトレーラー">セミトレーラー</MenuItem>
                    <MenuItem value="ポールトレーラー">ポールトレーラー</MenuItem>
                    <MenuItem value="フルトレーラー">フルトレーラー</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>車種</InputLabel>
              <Controller
                control={control}
                name="car_model"
                render={({ field }) => (
                  <Select
                    id="car_model"
                    label="車種"
                    type="text"
                    variant="standard"
                    {...field}
                    value={field.value || ''}
                  >
                    <MenuItem value={null}>&nbsp;</MenuItem>
                    <MenuItem value="平ボディ">平ボディ</MenuItem>
                    <MenuItem value="バンボディ">バンボディ</MenuItem>
                    <MenuItem value="ウィングボディ">ウィングボディ</MenuItem>
                    <MenuItem value="保冷車">保冷車</MenuItem>
                    <MenuItem value="冷凍車">冷凍車</MenuItem>
                    <MenuItem value="車載車">車載車</MenuItem>
                    <MenuItem value="重機運搬車">重機運搬車</MenuItem>
                    <MenuItem value="危険物運搬車">危険物運搬車</MenuItem>
                    <MenuItem value="ダンプ">ダンプ</MenuItem>
                    <MenuItem value="幌">幌</MenuItem>
                    <MenuItem value="ユニック">ユニック</MenuItem>
                    <MenuItem value="海上コンテナー用">海上コンテナー用</MenuItem>
                    <MenuItem value="その他">その他</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>床仕様</InputLabel>
              <Controller
                control={control}
                name="floor_specification"
                render={({ field }) => (
                  <Select
                    margin="dense"
                    id="floor_specification"
                    label="床仕様"
                    type="text"
                    fullWidth
                    variant="standard"
                    {...field}
                    value={field.value || ''}
                  >
                    <MenuItem value={null}>&nbsp;</MenuItem>
                    <MenuItem value="鉄板">鉄板</MenuItem>
                    <MenuItem value="ステンレス">ステンレス</MenuItem>
                    <MenuItem value="ジョルダー">ジョルダー</MenuItem>
                    <MenuItem value="縞板">縞板</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Stack>
          <Stack direction="row" gap={1}>
            <FormControl fullWidth>
              <InputLabel>荷台高さ</InputLabel>
              <Controller
                control={control}
                name="loading_platform_height"
                render={({ field }) => (
                  <Select
                    id="loading_platform_height"
                    label="荷台高さ"
                    type="text"
                    fullWidth
                    variant="standard"
                    {...field}
                    value={field.value || ''}
                  >
                    <MenuItem value={null}>&nbsp;</MenuItem>
                    <MenuItem value="標準">標準</MenuItem>
                    <MenuItem value="低床">低床</MenuItem>
                    <MenuItem value="中低床">中低床</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>荷台幅</InputLabel>
              <Controller
                control={control}
                name="loading_platform_width"
                render={({ field }) => (
                  <Select
                    id="loading_platform_width"
                    label="荷台幅"
                    type="text"
                    fullWidth
                    variant="standard"
                    {...field}
                    value={field.value || ''}
                  >
                    <MenuItem value={null}>&nbsp;</MenuItem>
                    <MenuItem value="標準">標準</MenuItem>
                    <MenuItem value="セミワイド">セミワイド</MenuItem>
                    <MenuItem value="ワイド">ワイド</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>荷台長さ</InputLabel>
              <Controller
                control={control}
                name="loading_platform_length"
                render={({ field }) => (
                  <Select
                    margin="dense"
                    id="loading_platform_length"
                    label="荷台長さ"
                    type="text"
                    fullWidth
                    variant="standard"
                    {...field}
                    value={field.value || ''}
                  >
                    <MenuItem value={null}>&nbsp;</MenuItem>
                    <MenuItem value="大型">大型</MenuItem>
                    <MenuItem value="中型">中型</MenuItem>
                    <MenuItem value="小型">小型</MenuItem>
                    <MenuItem value="ショート">ショート</MenuItem>
                    <MenuItem value="ロング">ロング</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Stack>
          <Stack>
            <FormControl component="fieldset">
              <>
                <FormLabel component="legend">装置</FormLabel>
                <FormGroup row>
                  <MultiCheckbox<TruckEntity, DesignatedTruckFeature>
                    form={form}
                    control={control}
                    name="features"
                    options={options}
                    defaultCheckedValues={getValues('features') ? getValues('features') : []}
                    valueSetter={setFeatures}
                  />
                </FormGroup>
              </>
            </FormControl>
          </Stack>
          <Stack direction="row" gap={1}>
            <TextField
              margin="dense"
              id="truck_fuel_cost_yen_per_km"
              label="1kmあたりの燃料費"
              type="text"
              fullWidth
              variant="standard"
              {...register('truck_fuel_cost_yen_per_km')}
              error={'truck_fuel_cost_yen_per_km' in errors}
              helperText={errors.truck_fuel_cost_yen_per_km?.message}
            />
            <TextField
              margin="dense"
              id="truck_insurance_fee_yen_per_day"
              label="1日あたりの保険料"
              type="text"
              fullWidth
              variant="standard"
              {...register('truck_insurance_fee_yen_per_day')}
              error={'truck_insurance_fee_yen_per_day' in errors}
              helperText={errors.truck_insurance_fee_yen_per_day?.message}
            />
            <TextField
              margin="dense"
              id="truck_repair_cost_yen_per_day"
              label="1日あたりの修繕費"
              type="text"
              fullWidth
              variant="standard"
              {...register('truck_repair_cost_yen_per_day')}
              error={'truck_repair_cost_yen_per_day' in errors}
              helperText={errors.truck_repair_cost_yen_per_day?.message}
            />
            <TextField
              margin="dense"
              id="truck_expressway_fee_yen_per_shift"
              label="1日あたりの高速代"
              type="text"
              fullWidth
              variant="standard"
              {...register('truck_expressway_fee_yen_per_shift')}
              error={'truck_expressway_fee_yen_per_shift' in errors}
              helperText={errors.truck_expressway_fee_yen_per_shift?.message}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <Button onClick={handleSubmit(onSubmitForm)} variant="contained">
          保存する
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default FormDialog;
