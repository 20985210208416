/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Box,
  LinearProgress,
  Paper,
  Theme,
  ThemeOptions
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { jaJP, GridColDef } from '@mui/x-data-grid';
import { DataGridPremium, GridFilterItem, GridFilterModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns-tz';
import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { SubmitHandler } from 'react-hook-form';
import { appBarHeight } from 'src/constants/layout';
import LicenseContext from 'src/contexts/LicenseContext';
import { GroupEntity } from 'src/entities/groupEntity';
import { RansackPredicateEntity } from 'src/entities/RansackPredicate.entity';
import { queryKey, useGroupsRequest as usePaginationRequest } from 'src/hooks/useGroups.request';
import { useMutationGroup } from 'src/hooks/useQueryGroups';
import operatorValueUtil from 'src/utils/operatorValue.util';
import stringUtil from 'src/utils/string.util';

import { Columns } from './Columns';
import CustomToolbar from './CustomToolbar';
import FormDialog from './FormDialog';

const V2GroupsPresenter: FC = memo(() => {
  const theme: Theme & ThemeOptions = useTheme();
  const licenseContext = useContext(LicenseContext);
  const queryClient = useQueryClient();

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(30);
  const [rowCount, setRowCount] = useState<number>(0);
  const [sortCondition, setSortCondition] = useState<string>('');
  const [orConditions, setOrConditions] = useState<RansackPredicateEntity[]>([]);
  const [andConditions, setAndConditions] = useState<RansackPredicateEntity[]>([]);
  const [searchCondition, setSearchCondition] = useState<string>('');
  const [selectedEntity, setSelectedEntity] = useState<GroupEntity|null>(null);
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const apiRef = useGridApiRef();

  const { data: requestData, isLoading: requestIsLoading } = usePaginationRequest(page, sortCondition, searchCondition);
  const { data: allRequestData } = usePaginationRequest(0, sortCondition, searchCondition, Number.MAX_SAFE_INTEGER);
  const { deleteGroup } = useMutationGroup();

  const editButtonOnclick = (entity: GroupEntity) => {
    setSelectedEntity(entity);
    setDialogIsOpen(true);
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (sortModel.length === 0) {
      setSortCondition('');
      return;
    }

    setSortCondition(
      [
        stringUtil.toSnakeCase(sortModel[0].field),
        sortModel[0].sort
      ].join('+')
    );
  };

  const onFilterChange = ((filterModel: GridFilterModel) => {
    const items = filterModel?.items;

    if (!items || items.length === 0) {
      setOrConditions([]);
      setAndConditions([]);
      return;
    }

    const ransackPredicates: RansackPredicateEntity[] = items.flatMap((item: GridFilterItem) => operatorValueUtil.convertToRansackPredicate(
      stringUtil.toSnakeCase(item.columnField),
      item.operatorValue,
      item.value as string | string[] | undefined,
      filterModel.linkOperator
    ));

    setOrConditions(ransackPredicates.filter((predicate) => predicate.link === 'or'));
    setAndConditions(ransackPredicates.filter((predicate) => predicate.link === 'and'));
  });

  const openNewGroupDialog = () => {
    setDialogIsOpen(true);
    setSelectedEntity(null);
  };

  const closeDialogIsOpen = () => {
    setDialogIsOpen(false);
  };

  const onSubmitRemove: SubmitHandler<GroupEntity> = useCallback(async (
    data,
  ): Promise<void> => {
    // eslint-disable-next-line no-alert
    if (window.confirm('削除してもよいですか？')) {
      await deleteGroup.mutateAsync(data.id);
      await queryClient.invalidateQueries([queryKey]);
    }
    setDialogIsOpen(false);
  }, [deleteGroup, queryClient]);

  const dataGridColumns: GridColDef[] = useMemo(() => Columns(editButtonOnclick, onSubmitRemove).filter((col) => {
    if (licenseContext?.config?.selectable_companies?.length > 1) {
      return true;
    }
    return col.field !== 'company_name';
  }), [licenseContext?.config, onSubmitRemove]);

  useEffect(() => {
    setSearchCondition(
      [
        operatorValueUtil.convertToQueryParams('or_conditions', orConditions),
        operatorValueUtil.convertToQueryParams('and_conditions', andConditions),
      ].filter((maybe) => maybe).join('&')
    );
  }, [andConditions, orConditions]);

  useEffect(() => {
    setRowCount(requestData?.totalCount || 0);
    setPageSize(requestData?.perPage || 30);
  }, [requestData]);

  const handleDownloadCSV = () => {
    const options = {
      utf8WithBom: true,
      fileName: `LOG-車両グループ一覧-${format(new Date(), 'yyyyMMddHHmmss', { timeZone: 'Asia/Tokyo' })}`
    };
    apiRef.current.setRows(allRequestData.data);
    apiRef.current.exportDataAsCsv(options);
    apiRef.current.setRows(requestData.data);
  };

  return (
    <>
      <Helmet>
        <title>車両グループ管理</title>
      </Helmet>
      <Box p={2}>
        {
          dialogIsOpen && (
            <FormDialog
              open={dialogIsOpen}
              onClose={closeDialogIsOpen}
              entity={selectedEntity}
            />
          )
        }
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Box flexGrow={1}>
            <Paper
              style={{
                width: '100%',
                height: `calc(100vh - ${theme.spacing(4)} - ${appBarHeight}px)`
              }}
            >
              <DataGridPremium
                apiRef={apiRef}
                rows={requestData?.data || []}
                columns={dataGridColumns}
                pagination
                disableRowGrouping
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: CustomToolbar
                }}
                componentsProps={{
                  toolbar: {
                    toggleDialogIsOpen: openNewGroupDialog,
                    handleDownloadCSV
                  }
                }}
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                loading={requestIsLoading}
                disableSelectionOnClick
                sortingMode="server"
                paginationMode="server"
                filterMode="server"
                page={page}
                pageSize={pageSize}
                rowCount={rowCount}
                onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onSortModelChange={handleSortModelChange}
                onFilterModelChange={onFilterChange}
                rowsPerPageOptions={[30]}
              />
            </Paper>
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default V2GroupsPresenter;
