import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import SystemUpdateAltRoundedIcon from '@mui/icons-material/SystemUpdateAltRounded';
import { Box, Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector
} from '@mui/x-data-grid';
import { FC, memo, useState } from 'react';

import MyGridToolbarFilterButton from '../common/MyGridToolbarFilterButton';

const CustomToolbar: FC<{
  toggleDialogIsOpen: () => void;
  onClickDeletePartnerCompanies: () => void;
  canDeleteAll: boolean;
  deleteAll: (settledFnk: () => void) => void;
  handleDownloadCSV: () => void;
}> = memo((
  {
    toggleDialogIsOpen,
    onClickDeletePartnerCompanies,
    canDeleteAll,
    deleteAll,
    handleDownloadCSV,
  }
  ) => {
  const [deleteHandlerDialogIsOpen, setDeleteHandlerDialogIsOpen] = useState<boolean>(false);

  const deleteHandlerDialogOnClose = () => {
    setDeleteHandlerDialogIsOpen(false);
  };

  const deleteBtnOnClick = () => {
    if (canDeleteAll) {
      setDeleteHandlerDialogIsOpen(true);

      return;
    }

    onClickDeletePartnerCompanies();
    setDeleteHandlerDialogIsOpen(false);
  };

  return (
    <>
      <GridToolbarContainer
        sx={{
          width: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end',
            width: '100%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start'
            }}
            flexGrow={1}
          >
            <GridToolbarColumnsButton nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
            <MyGridToolbarFilterButton />
            <GridToolbarDensitySelector nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
            <Button
              startIcon={<SystemUpdateAltRoundedIcon />}
              onClick={handleDownloadCSV}
              sx={{ textTransform: 'none' }}
            >
              エクスポート
            </Button>
            <Button
              startIcon={<AddCircleOutlinedIcon />}
              onClick={toggleDialogIsOpen}
            >
              運送会社追加
            </Button>
            <Button
              startIcon={<DeleteForeverOutlinedIcon />}
              onClick={deleteBtnOnClick}
            >
              運送会社削除
            </Button>
          </Box>
        </Box>
      </GridToolbarContainer>
      <Dialog
        open={deleteHandlerDialogIsOpen}
        onClose={deleteHandlerDialogOnClose}
      >
        <DialogTitle>
          削除する対象を選択してください
        </DialogTitle>
        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            startIcon={<DeleteForeverRoundedIcon />}
            onClick={() => {
              deleteAll(deleteHandlerDialogOnClose);
            }}
          >
            すべて削除する
          </Button>
          <Button
            onClick={() => {
              onClickDeletePartnerCompanies();
              setDeleteHandlerDialogIsOpen(false);
            }}
            variant="outlined"
            startIcon={<DeleteSweepRoundedIcon />}
          >
            選択したものを削除する
          </Button>
          <Button
            onClick={deleteHandlerDialogOnClose}
            startIcon={<HighlightOffRoundedIcon />}
          >
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default CustomToolbar;
