import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField, Theme, ThemeOptions
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FC, memo, useContext, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import MultiCheckbox from 'src/components/MultiCheckBox';
import LicenseContext from 'src/contexts/LicenseContext';
import { PartnerCompanyEntity, PartnerCompanyRequestEntity } from 'src/entities/PartnerCompanyEntity';
import { queryKey, useMutationPartnerCompanies, usePartnerCompanyMastersRequest } from 'src/hooks/usePartnerCompanies.request';

import { schema } from './schema';

type Props = {
  open: boolean;
  onClose: () => void;
  entity?: PartnerCompanyEntity;
}

const FormDialog:FC<Props> = memo((
  {
    open,
    onClose,
    entity,
  }
) => {
  const theme: Theme & ThemeOptions = useTheme();
  const licenseContext = useContext(LicenseContext);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [truckKlasses, setTruckKlasses] = useState<string[]>(entity?.truckKlasses ?? []);
  const [tempretureZones, setTempretureZones] = useState<string[]>(entity?.tempretureZones ?? []);

  const { addPartnerCompany, updatePartnerCompany } = useMutationPartnerCompanies();
  const { data: master } = usePartnerCompanyMastersRequest();

  const form = useForm<(PartnerCompanyRequestEntity | PartnerCompanyEntity)>({
    resolver: yupResolver(schema),
  });

  const {
    control,
    setValue,
    getValues,
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = form;

  const onSubmitAdd: SubmitHandler<PartnerCompanyRequestEntity | PartnerCompanyEntity> = async (
    data
  ): Promise<void> => {
    try {
      data.truckKlasses = truckKlasses;
      data.tempretureZones = tempretureZones;

      if ('id' in data) {
        await updatePartnerCompany.mutateAsync(data);
      } else {
        await addPartnerCompany.mutateAsync(data);
      }
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, quotes,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-argument
      error.response.data.map((str) => enqueueSnackbar(str));
    } finally {
      await queryClient.invalidateQueries([queryKey]);
      reset();
      onClose();
    }
  };

  const closeDialog = () => {
    onClose();
    reset();
  };

  const haveSelectableCompanies = licenseContext?.config?.selectable_companies?.length > 1;
  const isNew = !entity;

  useEffect(() => {
    if (entity) {
      reset(entity);
      setTruckKlasses(entity.truckKlasses ?? []);
      setTempretureZones(entity.tempretureZones ?? []);
      return;
    }

    reset({ companyId: licenseContext?.config?.selected_company_id });
    setTruckKlasses([]);
    setTempretureZones([]);
  }, [entity, reset, open, licenseContext?.config?.selected_company_id]);

  useEffect(() => {
    if (!licenseContext?.config?.selected_company_id) return;
    if (licenseContext?.config?.selected_company_id === 0) {
      reset({ companyId: 0 });
      return;
    }

    reset({ companyId: licenseContext?.config?.selected_company_id });
    setTruckKlasses([]);
    setTempretureZones([]);
  }, [licenseContext?.config?.selected_company_id, reset]);

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>
        運送会社
        {isNew ? '作成' : '編集'}
      </DialogTitle>
      <DialogContent>
        <Stack component="form" noValidate spacing={2}>
          {[haveSelectableCompanies, isNew].every((bool) => bool) && (
            <FormControl
              fullWidth
              size="small"
              sx={{
                marginTop: theme.spacing(1),
              }}
            >
              <InputLabel id="company_id_label" sx={{ pt: 1, ml: -1.5 }}>事業所</InputLabel>
              <Controller
                control={control}
                name="companyId"
                render={({ field }) => (
                  <Select
                    id="companyId"
                    margin="dense"
                    label="事業所"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={`${getValues('companyId')}`}
                    onChange={(e) => setValue('companyId', Number(e.target.value))}
                    disabled={!haveSelectableCompanies}
                  >
                    {licenseContext?.config?.selectable_companies?.map((company) => (
                      <MenuItem value={company.id} key={company.id}>{company.name}</MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          )}
          <TextField
            required
            margin="dense"
            id="name"
            label="会社名"
            type="text"
            fullWidth
            variant="standard"
            {...register('name')}
            error={'name' in errors}
            helperText={errors.name?.message}
          />
          <TextField
            required
            margin="dense"
            id="base"
            label="拠点名"
            type="text"
            fullWidth
            variant="standard"
            {...register('base')}
            error={'base' in errors}
            helperText={errors.base?.message}
          />
          <TextField
            required
            margin="dense"
            id="address"
            label="拠点住所"
            type="text"
            fullWidth
            variant="standard"
            {...register('address')}
            error={'address' in errors}
            helperText={errors.address?.message}
          />
          <TextField
            required
            margin="dense"
            id="contactPerson"
            label="担当者"
            type="text"
            fullWidth
            variant="standard"
            {...register('contactPerson')}
            error={'contactPerson' in errors}
            helperText={errors.contactPerson?.message}
          />
          <TextField
            required
            margin="dense"
            id="phoneNumber"
            label="電話番号"
            type="text"
            fullWidth
            variant="standard"
            {...register('phoneNumber')}
            error={'phoneNumber' in errors}
            helperText={errors.phoneNumber?.message}
          />
          <FormControl
            fullWidth
            size="small"
            sx={{
              marginTop: theme.spacing(1),
            }}
          >
            <Controller
              control={control}
              name="category"
              render={({ field }) => (
                <>
                  <InputLabel>カテゴリ</InputLabel>
                  <Select
                    id="category"
                    margin="dense"
                    label="事業所"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={`${getValues('category') ?? ''}`}
                    onChange={(e) => setValue('category', e.target.value)}
                  >
                    {master.categories?.map((category) => (
                      <MenuItem value={category} key={category}>{category}</MenuItem>
                    ))}
                  </Select>
                </>
              )}
            />
          </FormControl>
          <FormControl
            fullWidth
            size="small"
            sx={{
              marginTop: theme.spacing(1),
            }}
          >
            <>
              <FormLabel component="legend">利用可能な車両タイプ</FormLabel>
              <FormGroup row>
                <MultiCheckbox<PartnerCompanyRequestEntity | PartnerCompanyEntity, string>
                  form={form}
                  control={control}
                  name="truckKlasses"
                  options={master?.truckKlasses}
                  defaultCheckedValues={truckKlasses}
                  valueSetter={setTruckKlasses}
                />
              </FormGroup>
            </>
          </FormControl>
          <FormControl
            fullWidth
            size="small"
            sx={{
              marginTop: theme.spacing(1),
            }}
          >
            <>
              <FormLabel component="legend">利用可能な温度帯</FormLabel>
              <FormGroup row>
                <MultiCheckbox<PartnerCompanyRequestEntity | PartnerCompanyEntity, string>
                  form={form}
                  control={control}
                  name="tempretureZones"
                  options={master?.tempretureZones}
                  defaultCheckedValues={tempretureZones}
                  valueSetter={setTempretureZones}
                />
              </FormGroup>
            </>
          </FormControl>
          <TextField
            required
            margin="dense"
            id="memo"
            label="メモ"
            type="text"
            fullWidth
            variant="standard"
            {...register('memo')}
            error={'memo' in errors}
            helperText={errors.memo?.message}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>キャンセル</Button>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <Button onClick={handleSubmit(onSubmitAdd)} variant="contained">
          保存する
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default FormDialog;
