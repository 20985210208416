import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { HavePaginationEntity } from 'src/entities/HavePagination.entity';
import { PartnerCompanyEntity, PartnerCompanyMastersEntity, PartnerCompanyRequestEntity } from 'src/entities/PartnerCompanyEntity';

const requestPathBase = '/api/v4/master/partner_companies';
export const queryKey = 'usePartnerCompaniesRequest';

const getResources = async (page: number, sortCondition: string, searchCondition: string, per?: number) => {
  const requestParams = [
    `page=${page + 1}`,
    sortCondition ? `sort_condition=${sortCondition}` : '',
    searchCondition ? `${searchCondition}` : '',
    per ? `per=${per}` : '',
  ].filter((maybe) => maybe).join('&');

  const requestPath = [
    requestPathBase,
    requestParams,
  ].join('?');

  const response: AxiosResponse<HavePaginationEntity<PartnerCompanyEntity>> = await axios.get<HavePaginationEntity<PartnerCompanyEntity>>(requestPath);

  return response.data;
};

export const usePartnerCompaniesRequest = (page: number, sortCondition: string, searchCondition: string, per?: number) => useQuery(
  [queryKey, { page, sortCondition, searchCondition, per }],
  () => getResources(page, sortCondition, searchCondition, per),
);

const getMasters = async () => {
  const response: AxiosResponse<PartnerCompanyMastersEntity> = await axios.get<PartnerCompanyMastersEntity>('/api/v2/partner_companies/masters');
  return response.data;
};

export const usePartnerCompanyMastersRequest = () => useQuery(
  [queryKey, 'master'],
  () => getMasters(),
);

const toSnakeCase = (entity: PartnerCompanyRequestEntity | PartnerCompanyEntity) => Object.keys(entity).reduce((acc, key) => {
    const snakeKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    acc[snakeKey] = entity[key];
    return acc;
  }, {});

export const useMutationPartnerCompanies = () => {
  const queryClient = useQueryClient();
  return {
    addPartnerCompany: useMutation(
      (entity: PartnerCompanyRequestEntity) => axios.post('/api/v2/partner_companies', toSnakeCase(entity)),
      {
        onSuccess: () => queryClient.invalidateQueries(['usePartnerCompaniesRequest'])
      }
    ),
    updatePartnerCompany: useMutation(
      (entity: PartnerCompanyEntity) => axios.put(`/api/v2/partner_companies/${entity.id}`, toSnakeCase(entity)),
      {
        onSuccess: () => queryClient.invalidateQueries(['usePartnerCompaniesRequest'])
      }
    ),
    deletePartnerCompanies: useMutation(
      (ids: number[]) => axios.delete('/api/v2/partner_companies/bulk', { data: { ids } }),
      {
        onSuccess: () => {
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['usePartnerCompaniesRequest']);
        }
      }
    )
  };
};
