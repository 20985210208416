import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const requestPath = '/api/v4/master/delete_all/partner_companies';

export const useDeleteAllPartnerCompaniesMutation = () => ({
  post: useMutation(
    () => axios.post(requestPath),
  ),
});
