/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  LinearProgress,
  Paper,
  Theme,
  ThemeOptions,
  Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  jaJP,
  GridColDef,
  DataGridPremium,
  GridColumnResizeParams,
  GridRowParams,
  GridSortModel, GridFilterModel, GridFilterItem, useGridApiRef
} from '@mui/x-data-grid-premium';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { format } from 'date-fns-tz';
import { useSnackbar } from 'notistack';
import { FC, memo, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { appBarHeight } from 'src/constants/layout';
import LicenseContext from 'src/contexts/LicenseContext';
import { GarageEntity } from 'src/entities/garageEntity';
import { RansackPredicateEntity } from 'src/entities/RansackPredicate.entity';
import { useDeleteAllGaragesMutation } from 'src/hooks/useDeleteAllGarages.mutation';
import { queryKey, useGaragesRequest as usePaginationRequest } from 'src/hooks/useGarages.request';
import { useMutationGarage } from 'src/hooks/useQueryGarages';
import operatorValueUtil from 'src/utils/operatorValue.util';
import stringUtil from 'src/utils/string.util';

import { SelectAllSnackbars } from '../common/SelectAllSnackbars';

import { Columns } from './Columns';
import CustomToolbar from './CustomToolbar';
import FormDialog from './FormDialog';

const V2GaragesPresenter: FC = memo(() => {
  const theme: Theme & ThemeOptions = useTheme();
  const licenseContext = useContext(LicenseContext);
  const queryClient = useQueryClient();

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(30);
  const [rowCount, setRowCount] = useState<number>(0);
  const [sortCondition, setSortCondition] = useState<string>('');
  const [orConditions, setOrConditions] = useState<RansackPredicateEntity[]>([]);
  const [andConditions, setAndConditions] = useState<RansackPredicateEntity[]>([]);
  const [searchCondition, setSearchCondition] = useState<string>('');
  const [editEntity, setEditEntity] = useState<GarageEntity|null>(null);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [canDeleteAll, setCanDeleteAll] = useState<boolean>(false);

  const { data: requestData, isLoading: requestIsLoading } = usePaginationRequest(page, sortCondition, searchCondition);
  const { data: allRequestData } = usePaginationRequest(0, sortCondition, searchCondition, Number.MAX_SAFE_INTEGER);
  const { deleteGarages } = useMutationGarage();
  const { enqueueSnackbar } = useSnackbar();
  const { post } = useDeleteAllGaragesMutation();
  const apiRef = useGridApiRef();

  const deleteAll = (settledFnk: () => void) => {
    if (window.confirm(`選択された ${rowCount} 件の車庫を削除します。よろしいですか？`)) {
      setIsLoading(true);

      post.mutate(undefined, {
        onSuccess: () => {
          enqueueSnackbar('すべて削除しました');
        },
        onError: (error: AxiosError<{ message: string; }>) => {
          enqueueSnackbar(error.response?.data?.message);
        },
        onSettled: () => {
          queryClient
            .invalidateQueries([queryKey])
            .finally(() => {
              settledFnk();
              setIsLoading(false);
            });
        }
      });
    }
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (sortModel.length === 0) {
      setSortCondition('');
      return;
    }

    setSortCondition(
      [
        stringUtil.toSnakeCase(sortModel[0].field),
        sortModel[0].sort
      ].join('+')
    );
  };

  const onFilterChange = ((filterModel: GridFilterModel) => {
    const items = filterModel?.items;

    if (!items || items.length === 0) {
      setOrConditions([]);
      setAndConditions([]);
      return;
    }

    const ransackPredicates: RansackPredicateEntity[] = items.flatMap((item: GridFilterItem) => operatorValueUtil.convertToRansackPredicate(
      stringUtil.toSnakeCase(item.columnField),
      item.operatorValue,
      item.value as string | string[] | undefined,
      filterModel.linkOperator
    ));

    setOrConditions(ransackPredicates.filter((predicate) => predicate.link === 'or'));
    setAndConditions(ransackPredicates.filter((predicate) => predicate.link === 'and'));
  });

  const openEditDialog = (ent: GarageEntity) => {
    setEditEntity(ent);
    setDialogIsOpen(true);
  };
  const openNewFormDialog = () => {
    setEditEntity(null);
    setDialogIsOpen(true);
  };

  const onColumnWidthChange = (params: GridColumnResizeParams) => {
    const columns = dataGridColumns.map((col) => {
      let newDefinition = { field: col.field, width: col.width };
      if (col.field === params.colDef.field) {
        newDefinition = { ...newDefinition, width: params.colDef.width };
      }
      return newDefinition;
    });
    localStorage.setItem('garage-columns-width', JSON.stringify(columns));
  };

  const onSelectionModelChange = (garageIds: number[]) => {
    if (garageIds.length === requestData.totalCount) {
      setSelectedIds(garageIds);
    } else {
      // 全選択以外は画面上の項目のみ選択できる
      const set = new Set(requestData.data.map((it) => it.id));
      setSelectedIds(garageIds.filter((id) => set.has(id)));
    }
  };

  const onClickDeleteGarages = () => {
    if (selectedIds.length === 0) {
      enqueueSnackbar('削除する車庫を選択してください');
      return;
    }

    if (window.confirm(`選択された ${selectedIds.length} 件の車庫を削除します。よろしいですか？`)) {
      setIsLoading(true);
      deleteGarages.mutateAsync(selectedIds)
        .then(() => setSelectedIds([]))
        .catch((e: AxiosError<{ message: string; }>) => {
          enqueueSnackbar(e.response?.data?.message);
          setIsLoading(false);
        }).finally(() => {
          queryClient
            .invalidateQueries([queryKey])
            .finally(() => {
              setIsLoading(false);
            });
        });
    }
    setDialogIsOpen(false);
  };

  const onRowDoubleClick = (params: GridRowParams) => {
    setEditEntity(params.row as unknown as GarageEntity);
    setDialogIsOpen(true);
  };

  const closeFormDialog = () => {
    setDialogIsOpen(false);
    setEditEntity(null);
  };

  const dataGridColumns: GridColDef[] = useMemo(() => Columns(openEditDialog)
    .filter((col) => {
      if (licenseContext?.config?.selectable_companies?.length > 1) {
        return true;
      }
      return col.field !== 'company_name';
    }), [licenseContext.config]);

  useEffect(() => {
    const columnWidth = localStorage.getItem('garage-columns-width');
    if (columnWidth) {
      const columns = JSON.parse(columnWidth) as unknown as { field: string, width: number}[];
      dataGridColumns.forEach((col) => {
        const saved = columns.find((e) => col.field === e.field);
        if (saved && saved.width) {
          col.flex = undefined;
          col.width = saved.width;
        }
      });
    }
  }, [dataGridColumns]);

  useEffect(() => {
    setSearchCondition(
      [
        operatorValueUtil.convertToQueryParams('or_conditions', orConditions),
        operatorValueUtil.convertToQueryParams('and_conditions', andConditions),
      ].filter((maybe) => maybe).join('&')
    );
  }, [andConditions, orConditions]);

  useEffect(() => {
    setRowCount(requestData?.totalCount || 0);
    setPageSize(requestData?.perPage || 30);
  }, [requestData]);

  useEffect(() => {
    const canPaginate = rowCount > pageSize;

    if (!canPaginate) {
      setCanDeleteAll(false);
    }

    const selectedAll = setSelectedIds?.length === pageSize;

    setCanDeleteAll(selectedAll);
  }, [setSelectedIds, pageSize, rowCount]);

  const handleDownloadCSV = () => {
    const options = {
      utf8WithBom: true,
      allColumns: true,
      fileName: `LOG-車庫一覧-${format(new Date(), 'yyyyMMddHHmmss', { timeZone: 'Asia/Tokyo' })}`
    };
    apiRef.current.setRows(allRequestData.data);
    apiRef.current.setSelectionModel(selectedIds);
    apiRef.current.exportDataAsCsv(options);
    apiRef.current.setRows(requestData.data);
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setSelectedIds([]);
  };

  return (
    <>
      <Helmet>
        <title>車庫管理</title>
      </Helmet>
      <Box p={2}>
        <FormDialog
          open={dialogIsOpen}
          onClose={closeFormDialog}
          entity={editEntity}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <SelectAllSnackbars
            totalPageCount={requestData?.totalPageCount || 1}
            totalCount={requestData?.totalCount || 0}
            perPage={pageSize}
            allIds={allRequestData?.data.map((it) => it.id) || requestData?.allIds || []}
            selectedIds={selectedIds}
            apiRef={apiRef}
            setSelectedIds={setSelectedIds}
          />
          <Box flexGrow={1}>
            <Paper
              style={{
                width: '100%',
                height: `calc(100vh - ${theme.spacing(4)} - ${appBarHeight}px)`
              }}
            >
              <DataGridPremium
                apiRef={apiRef}
                rows={requestData?.data || []}
                columns={dataGridColumns}
                pagination
                checkboxSelection
                disableRowGrouping
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: CustomToolbar
                }}
                componentsProps={{
                  toolbar: {
                    toggleDialogIsOpen: openNewFormDialog,
                    onClickDeleteGarages,
                    canDeleteAll,
                    deleteAll,
                    handleDownloadCSV
                  }
                }}
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                loading={requestIsLoading || isLoading}
                onColumnWidthChange={(params) => onColumnWidthChange(params)}
                onSelectionModelChange={onSelectionModelChange}
                selectionModel={selectedIds}
                onRowDoubleClick={onRowDoubleClick}
                disableSelectionOnClick
                sortingMode="server"
                paginationMode="server"
                filterMode="server"
                page={page}
                pageSize={pageSize}
                rowCount={rowCount}
                onPageChange={onPageChange}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onSortModelChange={handleSortModelChange}
                onFilterModelChange={onFilterChange}
                keepNonExistentRowsSelected
                rowsPerPageOptions={[30]}
              />
            </Paper>
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default V2GaragesPresenter;
